import React from "react";
import { Link } from "react-router-dom";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


const Index = () => {
    

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 1,
        },
      };
      const responsiveTwo = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 1,
        },
      };
  return (
    <>
      <header>
        <nav>
          <div className="logo d-block d-lg-none">
            <img src="../Images/logo.svg" alt="" />
          </div>
          <input type="checkbox" id="checkbox" />
          <label htmlFor="checkbox" className="click-box">
            <img src="../Images/align.svg" alt="" />
          </label>
          <ul>
            <li>
              <Link to="#">Home</Link>
            </li>
            <li>
              <Link to="#">About</Link>
            </li>
            <li>
              <Link to="#">Programs</Link>
            </li>
            <li className="d-none d-lg-block">
              <Link to="#">
                <img src="../Images/logo.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="#">Events</Link>
            </li>
            <li>
              <Link to="#">Our Team</Link>
            </li>
            <li>
              <Link to="#" className="active-menu">
                {" "}
                Donate
              </Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hero">
        <div className="container">
          <div className="row">
            <div className="immortal">
              <div className="hero-heading">
                <h1>
                  The Immortal Life of <span>Henrietta Lacks</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lacks-banner">
        <div className="container">
          <div className="row">
            <div className="lacks">
              <img src="../Images/Layer.svg" alt="" className="layer" />
              <img src="../Images/woman.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="about-heading">
                <h3>About</h3>
                <p>
                  <span className="head">H</span>enrietta Lacks House "HeLa
                  House" was created to honor Henrietta Lacks and the
                  contribution she has made to every one of us on this planet.
                  Henrietta unknowingly changed the world with her immortal DNA
                  cells
                </p>
                <p className="pt-3">
                  After Henrietta’s death in 1951, a scientist discovered that
                  Henrietta’s DNA cells lived outside of her body and they never
                  died. In contrast, all other human cells died after a few
                  hours. Not only did Henrietta’s cells stay alive in the lab,
                  but the cells also replicated every 24 hours.
                </p>
                <img
                  src="../Images/Technology.png"
                  alt=""
                  className="img-fluid tec"
                />
              </div>
            </div>
            <div className="col-lg-7">
              <div className="heal-img">
                <div className="hela">
                  <img src="../Images/HeLa.svg" alt="" className="img-fluid" />
                </div>
                <div className="hela-name d-none d-md-block">
                  <p>
                    Photo Credits:
                    <br />
                    Families Fighting the Flu, INC
                  </p>
                </div>
                <div className="immortal">
                  <p>
                    The immortal DNA cells were coined “HeLa” which combined the
                    first 2 letters of her first name and last name. This huge
                    medical breakthrough allowed researchers to conduct
                    experiments on human cells outside of the body. HeLa cells
                    have played a crucial role in medical science.
                  </p>
                  <p>
                    Scientists have continued to use the immortal HeLa cell line
                    all over the world. Research involving polio vaccines,
                    chemotherapy, HIV/Aids, cloning, and IVF have all benefited
                    from the HeLa cell line.
                  </p>
                  <img
                    src="../Images/Technology.png"
                    alt=""
                    className="img-fluid tec-logy"
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="price-box">
                <div className="boxes">
                  <div className="box-h">
                    <h3>
                      <span>50</span> Million
                    </h3>
                    <p>
                      Metric tons of HeLa cells have been distributed around the
                      world
                    </p>
                  </div>
                  <img
                    src="../Images/million.svg"
                    alt=""
                    className="icon-img"
                  />
                </div>
                <div className="boxes">
                  <div className="box-h">
                    <h3>Trillions</h3>
                    <p>of HeLa cells have been reproduced</p>
                  </div>
                  <img
                    src="../Images/trillions.svg"
                    alt=""
                    className="icon-img"
                  />
                </div>
                <div className="boxes">
                  <div className="box-h">
                    <h3>Trillions</h3>
                    <p>
                      Metric tons of HeLa cells have been distributed around the
                      world
                    </p>
                  </div>
                  <img
                    src="../Images/million.svg"
                    alt=""
                    className="icon-img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="join-us">
        <div className="container-fluid">
          <div className="row px-0 align-items-end">
            <div className="col-12 px-0">
              <div className="line-img">
                <img
                  src="../Images/join-us-line.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5 px-0 order-2 order-lg-1">
              <div className="hela-v">
                <img
                  src="../Images/join-us-hela.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-7 px-0 order-1 order-lg-2">
              <div className="history-hela">
                <p>
                We love working with new Partners, Volunteers, Board Members, Students.
                </p>
                <p>We'd love to meet you!</p>
                <img
                  src="../Images/logo.svg"
                  alt=""
                  className="img-fluid join-logo"
                />
                <div className="join-this">
                  <img
                    src="../Images/JoinUs-logo.svg"
                    alt=""
                    className="img-fluid join-logo-item"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event d-none d-md-block">
        <div className="container">
          <div className="row">
            <div className="event-heading px-0 px-lg-3 ">
              <h2 className="heading">Events</h2>
            </div>
            <div className="col-lg-6 px-0 px-lg-3 mb-md-0 mb-4">
              <div className="card">
                <div className="card-img">
                </div>
                <div className="card-body">
                  <h3 className="h-c">
                    Event title, Lorem ipsum is a dummy text
                  </h3>
                  <p className="c-t">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <div className="resort">
                    <div className="gaylord">
                      <h3>Gaylord Rockies Resort and Convention Center.</h3>
                      <p>
                        200 West Madison Street, Suite 200 Chicago, IL 60606
                      </p>
                    </div>
                    <h3>Sunday, May 12, 2024 09 - 11 AM PST</h3>
                  </div>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 px-0 px-lg-3">
              <div className="card card-light">
                <div className="card-img">
                </div>
                <div className="card-body">
                  <h3 className="h-c">
                    Event title, Lorem ipsum is a dummy text
                  </h3>
                  <p className="c-t">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <div className="resort">
                    <div className="gaylord">
                      <h3>Gaylord Rockies Resort and Convention Center.</h3>
                      <p>
                        200 West Madison Street, Suite 200 Chicago, IL 60606
                      </p>
                    </div>
                    <h3>Sunday, May 12, 2024 09 - 11 AM PST</h3>
                  </div>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event d-block d-md-none">
        <div className="container">
          <div className="row">
            <div className="event-heading px-0 px-lg-3">
              <h2 className="heading">Events</h2>
            </div>
            <Carousel responsive={responsiveTwo}  infinite={true}>
            <div className="col-12">
              <div className="card">
                <div className="card-img">
                </div>
                <div className="card-body">
                  <h3 className="h-c">
                    Event title, Lorem ipsum is a dummy text
                  </h3>
                  <p className="c-t">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <div className="resort">
                    <div className="gaylord">
                      <h3>Gaylord Rockies Resort and Convention Center.</h3>
                      <p>
                        200 West Madison Street, Suite 200 Chicago, IL 60606
                      </p>
                    </div>
                    <h3>Sunday, May 12, 2024 09 - 11 AM PST</h3>
                  </div>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card">
                <div className="card-img">
                </div>
                <div className="card-body">
                  <h3 className="h-c">
                    Event title, Lorem ipsum is a dummy text
                  </h3>
                  <p className="c-t">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <div className="resort">
                    <div className="gaylord">
                      <h3>Gaylord Rockies Resort and Convention Center.</h3>
                      <p>
                        200 West Madison Street, Suite 200 Chicago, IL 60606
                      </p>
                    </div>
                    <h3>Sunday, May 12, 2024 09 - 11 AM PST</h3>
                  </div>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
            </Carousel>
          </div>
        </div>
      </div>
    
      <div className="what-are">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 px-lg-0">
              <div className="w-r">
                <h3>
                  What are<span>HeLa Cells</span>
                </h3>
              </div>
            </div>
            <div className="col-lg-6 px-lg-0">
              <div className="video-hela">
                <button className="play-click">
                    <img src="../Images/play-click-icon.svg" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <marquee width="100%" direction="left" scrollamount="1">
        <span>Henrietta Lacks</span> <span>Join our club</span>
        <span>HeLa House</span>
        <span>10 Million lives saved</span>
      </marquee>
      <div className="our-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 order-1 order-lg-1">
              <div className="mission-hela">
                <h3 className="heading">Our Mission</h3>
                <p className="sub-title com">
                  <span>H</span>enrietta Lacks House is committed to honoring
                  Henrietta’s legacy by raising awareness about health
                  disparities, social injustices, and systemic economic issues
                  that plague South and West side communities in Chicago.
                  Henrietta’s contribution to the world is priceless. Henrietta
                  unknowingly gave the world her immortal DNA cell line, known
                  as the HeLa cell line. It is estimated that trillions of HeLa
                  cells have been reproduced and over 75,000 medical studies
                  have been conducted. Over 10 million lives have been saved
                  using HeLa cells. Although the Lacks family will never be
                  compensated fairly for the use of Henrietta’s cells, we find
                  comfort in knowing that Henrietta Lacks House will funnel
                  millions of dollars into divested communities.
                </p>
                <p className="sub-title">
                  We partner with changemakers to unlock a wide range of
                  unprecedented solutions for disadvantaged communities by
                  including marginalized groups in our discussions, planning,
                  programming, and follow-up.
                </p>
              </div>
            </div>
            <div className="col-lg-7 order-2 order-lg-2">
              <div className="miss-hela">
                <img src="../Images/mission.png" alt="" className="img-fluid" />
                <p className="d-none d-lg-block">
                  Photo Credits:
                  <br />
                  Pexels - Luis Quintero
                </p>
              </div>
            </div>
            <div className="col-lg-5 order-4 order-lg-3">
              <div className="blood">
                <img
                  src="../Images/Rectangle23.png"
                  alt=""
                  className="img-fluid"
                />
                <p className="d-none d-lg-block">
                  Photo Credits:
                  <br />
                  Pexels - RDNE Stock project
                </p>
              </div>
            </div>
            <div className="col-lg-7 order-3 order-lg-4">
              <div className="our-vision">
                <h3 className="heading">Our Vision</h3>
                <p className="sub-title">
                  <span>H</span>enrietta Lacks House seeks to create an
                  ecosystem of resources that are easily accessible to members
                  of disadvantaged communities.{" "}
                </p>
                <ul>
                  <li>Healthy food</li>
                  <li>Mental health services</li>
                  <li>Emotional support services,</li>
                  <li>Individual and family counselling, </li>
                  <li>Improved educational resources,</li>
                  <li>ob opportunities, and</li>
                  <li>
                    Access to enrichment programs will be available to every
                    resident, with priority given to youth and senior
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="line-new" />
      <hr className="line-new-2" />
      <div className="our-mission">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="mission-hela programs">
                <h3 className="heading">
                  Programs & <br />
                  Services
                </h3>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="services-blood">
                <div className="s-b">
                  <h3>Clinical Research & Trials</h3>
                  <p>
                    Promoting clinical research will reduce health disparities,
                    save lives, gain insights about the safety and effectiveness
                    of drugs and other medical treatments, and discover
                    treatments for cancer, diabetes, heart disease, and other
                    illnesses that plague Black communities.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
                <div className="s-b">
                  <h3 className="pt-lg-5 pt-3">Community</h3>
                  <p>
                    Promoting clinical research will reduce health disparities,
                    save lives, gain insights about the safety and effectiveness
                    of drugs and other medical.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
                <div className="s-b s-b-r">
                  <h3 className="pt-lg-5 pt-3">Education</h3>
                  <p>
                    Promoting clinical research will reduce health disparities,
                    save lives, gain insights about the safety and effectiveness
                    of drugs and other medical treatments, and discover
                    treatments for cancer.
                  </p>
                  <Link to="#">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hbo">
        <div className="container">
            <div className="row">
                <div className="col-lg-5">
                    <div className="hbo-film">
                        <img src="../Images/hbo.svg" alt="" />
                        <h4>Film</h4>
                        <p>The Immortal Life of Henrietta Lacks</p>
                        <div className="arrow-button">
                          <button type="button">
                            <img src="../Images/left-error.svg" alt="" />
                          </button>
                          <button type="button">
                          <img src="../Images/right-error.svg" alt="" />
                          </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7">
                <Carousel responsive={responsive}>
                    <div className="c-card">
                        <img src="../Images/owl-c-1.png" alt="" className="img-fluid" />
                        <p>
                            Alfred Lacks Carter Jr. and Oprah Winfrey at the premier of The 
                            Immortal Life of Henrietta Lacks
                        </p>
                    </div>
                    <div className="c-card">
                        <img src="../Images/owl-c-2.png" alt="" className="img-fluid" />
                        <p>
                            In the HBO Film, Oprah Winfrey stars as Henrietta Lacks’ daughter, 
                            Deborah Lacks. The film chronicles Deborah's search to learn about the 
                            mother she never knew, and how the unauthorized harvesting of Lacks' cancerous 
                            cells in 1951 led to medical breakthroughs that saved millions of lives, and are 
                            still saving millions of lives today.
                        </p>
                    </div>
                    <div className="c-card">
                        <img src="../Images/Rectangle23.png" alt="" className="img-fluid" />
                        <p>
                            Alfred Lacks Carter Jr. and Oprah Winfrey at the premier of The 
                            Immortal Life of Henrietta Lacks
                        </p>
                    </div>
                </Carousel>
                
                </div>
            </div>
        </div>
      </div>
      <div className="press">
        <div className="container">
            <div className="row">
                <div className="col-lg-4">
                <h3 className="heading">Press <br/> Release</h3>
                </div>
                <div className="col-lg-8">
                    <div className="v-card">
                        <div className="health-card">
                            <div className="dr-img">
                            <img src="../Images/press-1.png" alt="" />
                            </div>
                            <div className="health-content">
                                <h3 className="all-heading-text">World Health Organisation</h3>
                                <p>
                                    Henrietta Lacks’ family appointed World Health Organization
                                    Goodwill Ambassadors for Cervical Cancer Elimination
                                </p>
                                <Link to="#" className="link-tag">Read More</Link>
                            </div>
                        </div>
                        <div className="health-card">
                            <div className="dr-img">
                            <img src="../Images/press-2.png" alt="" />
                            </div>
                            <div className="health-content">
                                <h3 className="all-heading-text">CBS News</h3>
                                <p>
                                    Attorney Ben Crump Announces He Will Represent Family 
                                    of Henrietta Lacks
                                </p>
                                <Link to="#" className="link-tag">Read More</Link>
                            </div>    
                        </div>
                        <div className="health-card">
                            <div className="dr-img">
                            <img src="../Images/press-3.png" alt="" />
                            </div>
                            <div className="health-content">
                                <h3 className="all-heading-text">World Health Organisation</h3>
                                <p>
                                    Henrietta Lacks’ family appointed World Health Organization Goodwill 
                                    Ambassadors for Cervical Cancer Elimination
                                </p>
                                <Link to="#" className="link-tag">Read More</Link>
                            </div>  
                        </div>
                        <div className="health-card">
                            <div className="dr-img">
                            <img src="../Images/press-4.png" alt="" />
                            </div>
                            <div className="health-content">
                                <h3 className="all-heading-text">CBS News</h3>
                                <p>
                                    Attorney Ben Crump Announces He Will Represent 
                                    Family of Henrietta Lacks
                                </p>
                                <Link to="#" className="link-tag">Read More</Link>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="group-img">
        <div className="container">
            <div className="row">
                    <div className="all-member d-none d-lg-block">
                        <img src="../Images/Rectangle-31.png" alt="" className="img-fluid" />
                        <div className="consortium">
                            <h3>HeLa</h3>
                            <h4>CONSORTIUM</h4>
                            <p>
                                The HeLa Consortium is an alliance of Doctors, Surgeons, vvNurse 
                                Practitioners, Scientists, Researchers, Therapists, and treatment 
                                facilities that organizes healthcare resources and operates cooperatively 
                                to serve low-income communities.
                            </p>
                    
                            <h5>Participating Cities</h5>
                            <ul>
                                <li>Chicago, IL</li>
                                <li>Los Angeles, CA</li>
                                <li>Flint, MI</li>
                                <li>Baltimore, MD</li>
                                <li>Atlanta, GA</li>
                                <li>New York, NY</li>
                                <li>Las Vegas, NV</li>
                            </ul>
                            <Link to="#" className="link-tag">Learn More</Link>
                        </div>
                    </div>
                    <div className="all-member d-block d-lg-none">
                        <div className="consortium">
                            <h3>HeLa</h3>
                            <h4>CONSORTIUM</h4>
                            <p>
                                The HeLa Consortium is an alliance of Doctors, Surgeons, vvNurse 
                                Practitioners, Scientists, Researchers, Therapists, and treatment 
                                facilities that organizes healthcare resources and operates cooperatively 
                                to serve low-income communities.
                            </p>
                    
                           
                        </div>
                        <img src="../Images/Rectangle-31.png" alt="" className="img-fluid" />
                        <div className="consortium">
                        <h5>Participating Cities</h5>
                            <ul>
                                <li>Chicago, IL</li>
                                <li>Los Angeles, CA</li>
                                <li>Flint, MI</li>
                                <li>Baltimore, MD</li>
                                <li>Atlanta, GA</li>
                                <li>New York, NY</li>
                                <li>Las Vegas, NV</li>
                            </ul>
                            <Link to="#" className="link-tag">Learn More</Link>
                        </div>
                 </div>
            </div>
        </div>
      </div>
      <div className="henrietta">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img src="../Images/image4.png" alt="" className="img-fluid" />
            </div>
            <div className="col-lg-6">
              <img src="../Images/image5.png" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      <div className="social-feeds">
            <div className="container">
                <div className="row">
                    <div className="heading s-f px-md-0 px-3">Social Feeds</div>
                </div>
            </div>
      </div>
      <div className="social-img">
        <div className="container">
            <div className="row">
                <div className="px-0">
                    <img src="../Images/rectangle-42.png" alt="" className="img-fluid" />
                </div>
            </div>
        </div>
      </div>
      <footer>
        <div className="container">
            <div className="row">
                <div className="col-lg-4 order-2 order-lg-1">
                    <div className="address">
                        <img src="../Images/logo.svg" alt="" />
                        <address>200 West Madison Street<br/> Suite 200 <br/>Chicago, IL 60606<br/> 
                            312-532-4594 info@helahouse.org
                        </address>
                        <p>Privacy policy</p>
                        <p>© 2024 HeLa House.</p>
                    </div>
                </div>
                <div className="col-lg-8 order-1 order-lg-2">
                    <div className="contact-form">
                        <h3>Contact</h3>
                        <form action="" className="form-contact">
                              <div className="f-f">
                                <label htmlFor="name">Name name</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="f-f  mt-4">
                                <label htmlFor="L-name">Last name</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="f-f mt-4">
                                <label htmlFor="mail">Email</label>
                                <input type="email" className="form-control" />
                              </div>
                              <div className="f-f mt-4">
                                <label htmlFor="phone">Phone</label>
                                <input type="tel" className="form-control" />
                              </div>
                              <div className="f-f mt-4">
                                <label htmlFor="text">Subject</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="f-f mt-4">
                                <label htmlFor="text" className="text-l">Message</label>
                                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                              </div>
                              <div className="text-center text-md-end">
                              <button type="submit" className="sub-mit">Submit</button>
                              </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </footer>
    </>
  );
};

export default Index;
